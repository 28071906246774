import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { uppercase } from 'angular';
import { upperCase } from 'lodash';

@Component({
  selector: 'app-file-upload-attachment-modal',
  templateUrl: './file-upload-attachment-modal.component.html',
  styleUrls: ['./file-upload-attachment-modal.component.scss'],
})
export class FileUploadAttachmentModalComponent implements OnInit {
  public attachment: any;
  public tmpFile: any;
  public type: string;
  public isEdition: boolean;
  public titleTranslateKey: string;
  public placeholderTranslateKey: string;
  public saveButtonIsDisable: boolean;
  public onUploadSuccess = new EventEmitter<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FileUploadAttachmentModalComponent>) {}

  public ngOnInit(): void {
    this.isEdition = !!this.data.attachment.url;
    this.saveButtonIsDisable = this.isEdition ? false : true;
    this.type = this.data.type;
    this.titleTranslateKey = 'COMMON.MODALS.ATTACHMENT.ADD.' + this.type.toUpperCase();
    this.placeholderTranslateKey = 'COMMON.MODALS.ATTACHMENT.PLACEHOLDER_TITLE.' + this.type.toUpperCase();
    this.tmpFile = this.type !== 'files' ? this.data.attachment : { title: '', url: '' };
  }
  onUploadFileSuccess($event) {
    this.saveButtonIsDisable = false;
    this.tmpFile = { ...$event, title: this.tmpFile.title };
  }
  public saveAttachment(): void {
    if (this.tmpFile.uploadedUrl || this.type === 'link') {
      this.attachment = this.tmpFile;
    }
    this.closeModal();
  }

  public closeModal(): void {
    this.dialogRef.close(this.attachment);
  }
}
