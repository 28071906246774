import * as moment from 'moment';
import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { HolFlight } from '../../../models/hol-flight.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { HolIndicatorsStatusForFilter, HolIndicatorStatusTypeEnum } from '../../../enums/hol-indicator-status-type.enum';
import { BaseComponent } from '../../base/base.component';
import Timer = NodeJS.Timer;
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { CommonStoreState } from 'src/app/common/store/common.model';
import { FltFlightService } from '../../../../flt/services/flt-flight.service';
import { RolesService } from 'src/app/common/services/roles.service';

// declare var angular: angular.IAngularStatic;

@Component({
  selector: 'app-indicators-panel',
  templateUrl: './indicators-panel.component.html',
  styleUrls: ['./indicators-panel.component.scss'],
})
export class IndicatorsPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  public RolesService = RolesService;
  allFlights: Array<HolFlight>;
  todayFlights: Array<HolFlight>;
  tomorrowFlights: Array<HolFlight>;
  yesterdayFlights: Array<HolFlight>;
  departureSelected;
  statusFilterActivated = false;
  airports;
  today: any;
  tomorrow: any;
  yesterday: any;

  indicatorsStatusForFilter = HolIndicatorsStatusForFilter;
  indicatorFilterSelected: HolIndicatorStatusTypeEnum | string;

  @Input() expanded: boolean;
  @Input() accessRights;
  @Input() isInBox: boolean;
  flightsSubscription: Subscription;
  flightStatusUpdateInterval: Timer;

  constructor(
    @Inject('$state') private $state,
    private flightService: FltFlightService,
    public moduleConfig: ModuleConfigService,
    private commonStoreManager: CommonStoreManager
  ) {
    super();
  }

  ngOnInit(): void {
    this.today = moment().utc(false);
    // this.today = moment('2020-07-03T12:00:00.000Z').utc(false);
    this.tomorrow = moment(this.today).utc(false).startOf('day').add(1, 'day');
    this.yesterday = moment(this.today).startOf('day').utc(false).subtract(1, 'day');
    this.initFlightLists();
  }

  public goToFlight(flightObjectdId): void {
    if (this.moduleConfig.config.canOpenFlight) {
      this.$state.go('app.ops.dashboardflight', {
        flightId: flightObjectdId,
      });
    }
  }

  selectDepartureAirport($event): void {
    this.departureSelected = _.cloneDeep($event.value);
  }

  toggleStatusFilter(): void {
    this.statusFilterActivated = _.cloneDeep(!this.statusFilterActivated);
  }

  selectIndicatorFilter($event): void {
    this.indicatorFilterSelected = _.cloneDeep($event);
  }

  // TODO MAYBE USELESS
  // flightsFilter = (flight) => {
  //   let displayed = true;
  //   if (flight) {
  //     if (this.departureSelected && this.departureSelected !== 'All') {
  //       displayed = (flight.departure === this.departureSelected);
  //     }
  //     if (displayed && !this.statusFilterActivated) {
  //       displayed = !FlightService.hasTakenOff(flight.status);
  //     }
  //   }

  //   return displayed;
  // };

  ngOnDestroy(): void {
    this.flightsSubscription.unsubscribe();
    clearInterval(this.flightStatusUpdateInterval);
  }

  trackByFn(index: any): any {
    return index;
  }

  private initFlightLists(): void {
    this.flightsSubscription = this.commonStoreManager.commonState.subscribe((commonState: CommonStoreState) => {
      this.allFlights = commonState.flights;
      this.airports = _.uniqBy(commonState.flights, 'departure')
        .map(f => f.departure)
        .sort();
      this.todayFlights = commonState.flights.filter(
        (f: HolFlight) => this.today.isSame(moment(f.std).utc(false), 'day') || this.today.isSame(f.sta, 'day')
      );
      this.tomorrowFlights = commonState.flights.filter(
        (f: HolFlight) => this.tomorrow.isSame(moment(f.std), 'day') || this.tomorrow.isSame(f.sta, 'day')
      );
      this.yesterdayFlights = commonState.flights.filter(
        (f: HolFlight) => this.yesterday.isSame(moment(f.std), 'day') || this.yesterday.isSame(f.sta, 'day')
      );
    });
  }
}
