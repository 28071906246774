import { OnInit } from '@angular/core';

export class BaseComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  public trackByFunction(index, item) {
    if (item && item.objectId) {
      return item.objectId;
    }
    if (item && !item.objectId && item.items && item.items[0].objectId) {
      return item.items[0].objectId;
    }

    return index;
  }
}
