import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclSmsService } from './../../ocl/services/ocl-sms-service/ocl-sms.service';
import { FltLogbook } from './../models/flt-logbook';
import { HolContext } from './../../common/models/hol-context.model';
import { HolAttachments, HolisFile } from 'src/app/common/models/hol-attachments.model';
import { Inject, Injectable } from '@angular/core';
import { HolTag } from '../../common/models/hol-tag';
import { HolFlight } from '../../common/models/hol-flight.model';
import { OclLogbookService } from '../../ocl/services/ocl-logbook-service/ocl-logbook.service';
import { RequestService } from '../../common/services/request.service';
import { OclOptionsService } from '../../ocl/services/ocl-options-service/ocl-options.service';
import { OclHistoryService } from '../../ocl/services/ocl-history-service/ocl-history.service';
import { ParseMapperService } from '../../common/services/parse-mapper.service';
import { OclLogbookTagService } from '../../ocl/services/ocl-logbook-tag-service/ocl-logbook-tag.service';
import { GocFlightLogbookService } from '../../goc/services/goc-flight-logbook-service/goc-flight-logbook.service';
import { OclLogBooksStoreManager } from '../../ocl/store/logbooks/ocl-log-books-store-manager.service';
import { ModuleConfigService } from '../../common/services/module-config/module-config.service';
import { FltFlightService } from './flt-flight.service';
import { FltFlightLogbookService } from './flt-flight-logbook.service';
import * as _ from 'lodash';
import { differenceBy, flatten, intersectionBy } from 'lodash';
import * as moment from 'moment';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { FltApplicabilityService } from './flt-applicability.service';
import { OclMailService } from 'src/app/ocl/services/ocl-mail-service/ocl-mail.service';

@Injectable({
  providedIn: 'root',
})
export abstract class FltLogbookService<T extends FltLogbook = FltLogbook> extends OclLogbookService<FltLogbook> {
  // tslint:disable:variable-name
  protected abstract ParseFlightLogbook;
  protected ParseFlight = Parse.Object.extend('GOCFlight');

  // tslint:enabled

  protected constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    @Inject('FilesService') protected filesService,
    @Inject('UserService') protected userService,
    @Inject('$translate') protected $translate: any,
    protected optionsService: OclOptionsService,
    protected historyService: OclHistoryService,
    protected parseMapper: ParseMapperService,
    protected logBookTagService: OclLogbookTagService,
    protected gocLogbookService: GocFlightLogbookService,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    public moduleConfig: ModuleConfigService,
    protected flightService: FltFlightService,
    protected flightLogbookService: FltFlightLogbookService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    protected notificationsService: NotificationsService,
    protected smsService: OclSmsService,
    protected mailService: OclMailService,
    public commonStoreManager: CommonStoreManager
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      optionsService,
      historyService,
      parseMapper,
      logBookTagService,
      gocLogbookService,
      occLogBooksStoreManager,
      moduleConfig,
      notificationsService,
      smsService,
      mailService,
      commonStoreManager
    );
  }

  public setAdditionalFields(logbook: T, parseLogbook: Parse.Object) {
    if (logbook.applicability) {
      logbook.applicability.updateParseObject(parseLogbook);
    }
    if (logbook.isFromFlight && logbook.isFromFlight.objectId !== null) {
      parseLogbook.set(
        'isFromFlight',
        new this.ParseFlight({
          id: logbook.isFromFlight.objectId,
        })
      );
    }
  }

  public create(logbook: T, notifications: HolNotification[], context: HolContext): Promise<T> {
    let addBreakLinesBefore = false;
    if (logbook.attachments && logbook.attachments.note) {
      addBreakLinesBefore = true;
    }
    return super.create(logbook, notifications).then(savedLogbook => {
      logbook.objectId = savedLogbook.objectId;
      if (logbook.applicability) {
        this.flightService.getFlightsForApplicability(logbook.applicability).then(applicableFlights => {
          if (logbook.isFromFlight && applicableFlights.find(f => f.objectId === logbook.isFromFlight.objectId)) {
            applicableFlights = applicableFlights.filter(f => f.objectId !== logbook.isFromFlight.objectId);
          }
          const logbooks = flatten(
            applicableFlights.map(f => {
              return this.getFlightLogbookFromLogbook(f, logbook);
            })
          );
          this.requestService.performSaveAllQuery(logbooks).then();
        });
      }
      return this.applicabilityHistoryInNotes(logbook, context, addBreakLinesBefore).then(attachments => {
        logbook.attachments = attachments;
        const parseLogbook = new this.ParseLogbook();
        parseLogbook.id = logbook.objectId;
        parseLogbook.set('attachments', JSON.stringify(attachments));
        return this.requestService.performSaveQuery(parseLogbook).then(l => {
          // console.log('newLogbook', l, l.tags)
          const updatedLogbook = this.newLogbook(l, l.tags);
          this.occLogBooksStoreManager.updateOneLogBook(updatedLogbook);
          return updatedLogbook;
        });
      });
    });
  }

  public async update(logbook: T, context: HolContext): Promise<T> {
    const oldLogbook = new FltLogbook(new this.ParseLogbook({ id: logbook.objectId }));
    const oldApplicability = oldLogbook.applicability;
    const newApplicability = logbook.applicability;

    return super.update(logbook).then(res => {
      return Promise.all([
        this.flightLogbookService.getAllFromLogbook(logbook),
        this.flightService.getFlightsForApplicability(logbook.applicability),
      ]).then(([oldFlightLogbooks, matchingFlights]) => {
        const newFlightLogbooks = flatten(
          matchingFlights.map(f => {
            return this.getFlightLogbookFromLogbook(f, logbook);
          })
        );

        // console.log('######################################');
        // console.log('Existing (' + oldFlightLogbooks.length + ')\n', oldFlightLogbooks);
        // console.log('Matching Appl (' + newFlightLogbooks.length + ')\n', newFlightLogbooks);
        // console.log('---------------------------------------');
        const compareFlightLogbooks = flb => {
          return flb.get('flight').id + '#' + flb.get('station');
        };
        const toRemove = differenceBy(oldFlightLogbooks, newFlightLogbooks, compareFlightLogbooks);
        const toUpdate = intersectionBy(oldFlightLogbooks, newFlightLogbooks, compareFlightLogbooks);
        const toCreate = differenceBy(newFlightLogbooks, oldFlightLogbooks, compareFlightLogbooks);
        // console.log('To remove (' + toRemove.length + ')\n', toRemove);
        // console.log('To update (' + toUpdate.length + ')\n', toUpdate);
        // console.log('To create (' + toCreate.length + ')\n', toCreate);

        return Promise.all([this.requestService.performDestroyAllQuery(toRemove), this.requestService.performSaveAllQuery(toCreate)]).then(
          () => {
            if (!_.isEqual(newApplicability, oldApplicability)) {
              return this.applicabilityHistoryInNotes(res, context).then(attachments => {
                logbook.attachments = attachments;
                const parseDecision = new this.ParseLogbook();
                parseDecision.id = res.objectId;
                parseDecision.set('attachments', JSON.stringify(attachments));
                return this.requestService.performSaveQuery(parseDecision).then(l => {
                  return this.newLogbook(l, l.tags);
                });
              });
            } else {
              return res as T;
            }
          }
        );
      });
    });
  }

  public applicabilityHistoryInNotes(logbook: FltLogbook, context: HolContext, addMdBreakLinesBefore = false) {
    let content = '';
    let nameFile;
    const dateFormat = 'DD/MM/YY HH:mm[Z]';
    const applicabilityDate = moment.utc(logbook.updatedAt ? logbook.updatedAt : logbook.createdAt).format(dateFormat);
    const attachments = logbook.attachments ? logbook.attachments : new HolAttachments();
    const applicability = logbook.applicability;
    const noteFile: HolisFile = new HolisFile();

    if (logbook.attachments && logbook.attachments.note) {
      content += logbook.attachments.note;
      nameFile = logbook.attachments.noteFile.fileName;
    } else {
      nameFile = `note-${context.module.toLocaleLowerCase()}-${context.category
        .substring(0, 3)
        .toLocaleLowerCase()
        .replace(/é|è|ê/g, 'e')}-${moment().utc().format('DD-MM-YYYY')}.html`;
      addMdBreakLinesBefore = true;
    }

    content += addMdBreakLinesBefore ? '\n' + '\n' + '\n' : '';
    content += this.applicabilityService.applicabilityHistoryTxt(applicability, applicabilityDate);

    const mdTemplate = content;

    const htmlContent = this.markdownService.parseMdToHtml(content);
    const htmlTemplate = this.markdownService.createHtmlContent(htmlContent, context);

    const blob = new Blob([htmlTemplate], { type: 'text/html' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        this.filesService.uploadFile(nameFile, { base64: reader.result }).then(
          url => {
            noteFile.url = url;
            noteFile.fileName = nameFile;
            attachments.note = mdTemplate;
            attachments.noteFile = noteFile;
            resolve(attachments);
          },
          err => {
            reject(err);
          }
        );
      };
    });
  }

  protected initFlightLogbookFromLogbook(flight: HolFlight, logbook: T) {
    const flightLogbook = new this.ParseFlightLogbook();
    flightLogbook.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightLogbook.set('logbook', new this.ParseLogbook({ id: logbook.objectId }));
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key, value]) => {
        if (key.startsWith('role:') && !_.startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightLogbook.setACL(flight.acl);
    } else {
      flightLogbook.setACL(logbook.acl);
    }
    return flightLogbook;
  }

  protected newLogbook(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return new FltLogbook(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }

  private getFlightLogbookFromLogbook(flight: HolFlight, logbook: T): Parse.Object[] {
    const flightLogbook = this.initFlightLogbookFromLogbook(flight, logbook);

    if (logbook.applicability.flightsDirection === 'DEP' || logbook.applicability.stationsDirection === 'OUT') {
      flightLogbook.set('station', flight.departure);
    } else if (logbook.applicability.flightsDirection === 'ARR' || logbook.applicability.stationsDirection === 'IN') {
      flightLogbook.set('station', flight.destination);
    } else if (!logbook.applicability.stationsDirection && logbook.applicability.stations) {
      const flightLogbooks = [];
      logbook.applicability.stations.forEach(station => {
        if (flight.departure === station || flight.destination === station) {
          const tempLogbook = this.initFlightLogbookFromLogbook(flight, logbook);
          tempLogbook.set('station', station);
          flightLogbooks.push(tempLogbook);
        }
      });
      return flightLogbooks;
    }
    return [flightLogbook];
  }

  protected getAdditionnalQueries(query, queryPinned, today) {
    // Filtrage du tableau de bord par date
    // - Soit T = date sélectionnée à 00:00Z ou date à 00:00Z si aucune date n’est sélectionnée
    // - Soit C = date de création d’une carte `createdAt`
    // - Soit S = date de fin d’applicabilité escale d’une carte
    // - Soit F = Max(STA) des vols applicables à une carte
    // | Applicabilité LOGBOOK | Visibilité                                                                                   | Prise en compte valeur `…ToDisplay` |
    // | ------------------------------ | ----------------------------------------------------------------------------------- | ----------------------------------- |
    // | ESCALES                        | `C ≤ T && S ≥ T`                                                                    | NON                                 |
    // | VOLS                           | `C ≤ T && F ≥ T`                                                                    | NON                                 |
    // | TOUS VOLS                      | Toujours                                                                            | NON                                 |=> NON DEV pour l'instant
    // | AUCUNE                         | Si  `MAX(createdAt, customCreatedAt?)`  appartient à l’intervalle  `T - …ToDisplay` | OUI                                 |
    if (this.moduleConfig.config.canChooseDataStartDate && today instanceof Date && today !== undefined) {
      query.doesNotExist('applStations');
      query.doesNotExist('applFlights');

      const todayStart = moment.utc(today).startOf('day');
      const todayEnd = moment.utc(today).endOf('day');

      const queryStationApplicability = new Parse.Query(this.ParseLogbook);
      queryStationApplicability.exists('applStations');
      queryStationApplicability.notEqualTo('applStations', '');
      queryStationApplicability.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryStationApplicability.greaterThanOrEqualTo('validTo', todayStart.toDate());

      const queryStationApplicability2 = new Parse.Query(this.ParseLogbook);
      queryStationApplicability2.exists('applStations');
      queryStationApplicability2.notEqualTo('applStations', '');
      queryStationApplicability2.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryStationApplicability2.doesNotExist('validTo');

      const queryLinkedFlights = new Parse.Query(this.ParseFlight);
      queryLinkedFlights.greaterThanOrEqualTo('sta', todayStart.toDate());
      queryLinkedFlights.notEqualTo('status', 'C');

      const queryFlightsApplicability = new Parse.Query(this.ParseLogbook);
      queryFlightsApplicability.exists('applFlights');
      queryFlightsApplicability.notEqualTo('applFlights', '');
      queryFlightsApplicability.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryFlightsApplicability.matchesQuery('applFlights', queryLinkedFlights);

      return Parse.Query.or(query, queryPinned, queryStationApplicability, queryStationApplicability2, queryFlightsApplicability);
    } else {
      return Parse.Query.or(query, queryPinned);
    }
  }
}
