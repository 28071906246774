'use strict';

angular
  .module('gestiondecriseApp')
  /**
   * @ngdoc SmsService
   * @name gestiondecriseApp.SmsService
   * @description
   * # SmsService
   * Service in the gestiondecriseApp.
   */
  .service('SmsService', function (
    ErpUsersService,
    UserService,
    SmsSenderService,
    $q,
    $translate,
    FunctionService,
    $log,
    OptionsService,
    CONSTANTS
  ) {
    this.sendNewMELInfoSMS = function (mel, melInfo, phoneNumbers) {
      const messages = [];
      for (let i = 0; i < phoneNumbers.length; i++) {
        const phoneNumber = phoneNumbers[i];
        messages.push({
          to: phoneNumber,
          text: _getNewMELInfoMessage(mel, melInfo),
        });
      }
      SmsSenderService.sendMultipleSms(messages, true, CONSTANTS.COMPANY_NAME + CONSTANTS.MCC_SUFFIX);
    };

    this.sendNewSituationSms = function (situation) {
      if (!situation.user || !situation.user.phone) {
        $log.warn('SmsService::sendNewSituationSms: no user phone');
        return;
      }
      SmsSenderService.sendSms(situation.user.phone, _getSituationMessage(situation), true, CONSTANTS.COMPANY_NAME + CONSTANTS.CREW_SUFFIX);
    };

    this.sendNewMCCEventSMS = function (event, phoneNumbers) {
      const messages = [];
      for (let i = 0; i < phoneNumbers.length; i++) {
        const phoneNumber = phoneNumbers[i];
        messages.push({
          to: phoneNumber,
          text: _getNewMCCEventMessage(event),
        });
      }
      SmsSenderService.sendMultipleSms(messages, true, CONSTANTS.COMPANY_NAME + CONSTANTS.MCC_SUFFIX);
    };

    function _getNewMELInfoMessage(mel, melInfo) {
      return $translate.instant('SMS.MCC.NEW_MEL_INFO', {
        dueDate: moment(mel.dueDate).utc().format('ddd DD/MM'),
        foundDate: moment(mel.foundDate).utc().format('ddd DD/MM'),
        reference: mel.reference,
        faultType: mel.faultType,
        faultName: mel.faultName,
        nextInfoTime: moment(melInfo.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
        appUrl: location.origin,
      });
    }

    function _getSituationMessage(situation) {
      const translateKey = 'SMS.CREW.NEW_SITUATION_' + situation.direction;

      return $translate.instant(translateKey, situation);
    }

    function _getNewMCCEventMessage(event) {
      const lastInfo = event.infos && event.infos.length ? event.infos[0] : null;
      return $translate.instant('SMS.MCC.NEW_EVENT', {
        aircraft: event.aircraft && event.aircraft.registration,
        ataCode: event.ataCode && event.ataCode.code,
        ataDescription: event.ataCode && event.ataCode.description,
        description: event.description,
        nextInfoTime: lastInfo && lastInfo.nextInfoTime && moment(lastInfo.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
        nextInfoText: lastInfo && lastInfo.message,
      });
    }
  })
  /**
   * @ngdoc SmsSenderService
   * @name gestiondecriseApp.SmsSenderService
   * @description
   * # SmsSenderService
   * Service in the gestiondecriseApp.
   */
  .service('SmsSenderService', function ($http, $q, CONSTANTS, OptionsService, RequestService) {
    /**
     * @name sendSms
     * @param phoneNumber
     * @param content
     * @param forceToSendSms
     * @param sender
     * @returns {Promise}
     */
    this.sendSms = function (phoneNumber, content, forceToSendSms, sender) {
      const deferred = $q.defer();
      // If we don't force to send sms
      // And if hasToSendSms flag is false,
      // ==> We don't send any sms
      if (forceToSendSms !== true && !OptionsService.hasToSendSms()) {
        return $q.resolve();
      }
      const data = {
        phoneNumber: phoneNumber,
        content: content,
        sender: sender || CONSTANTS.COMPANY_NAME,
      };
      RequestService.performCloudCode('sendSms', data, deferred.resolve, deferred.reject);
      return deferred.promise;
    };

    /**
     * @name sendMultipleSms
     * @param messages
     * @param forceToSendSms
     * @returns {Promise}
     */
    this.sendMultipleSms = function (messages, forceToSendSms, sender) {
      const deferred = $q.defer();
      const data = {
        messages: messages,
        sender: sender,
      };
      // If we don't force to send sms
      // And if hasToSendSms flag is false,
      // ==> We don't send any sms
      if (forceToSendSms !== true && !OptionsService.hasToSendSms()) {
        return $q.resolve();
      }
      RequestService.performCloudCode('sendMultipleSms', data, deferred.resolve, deferred.reject);
      return deferred.promise;
    };
  });
