import { Action } from '@ngrx/store';

export enum OclLinkedDisplayedItemsActionTypes {
  ADD_OR_UPDATE_LINKED_DISPLAYED_ITEMS = '[OCL LINKED DISPLAYED ITEMS] Add or Update Linked Diplayed Items',
  REMOVE_LINKED_DISPLAYED_ITEMS = '[OCL LINKED DISPLAYED ITEMS] Remove Linked Diplayed Items',
  RESET_LINKED_DISPLAYED_ITEMS = '[OCL LINKED DISPLAYED ITEMS] RESET Linked Diplayed Items',
}

export class AddOrUpdateLinkedDiplayedItems implements Action {
  readonly type = OclLinkedDisplayedItemsActionTypes.ADD_OR_UPDATE_LINKED_DISPLAYED_ITEMS;
  constructor(public linkedUid: string, public color: string, public itemsToUpdate: any[]) {}
}
export class RemoveLinkedDiplayedItems implements Action {
  readonly type = OclLinkedDisplayedItemsActionTypes.REMOVE_LINKED_DISPLAYED_ITEMS;
  constructor(public linkedUid: string, public color: string) {}
}
export class ResetLinkedDisplayedItems implements Action {
  readonly type = OclLinkedDisplayedItemsActionTypes.RESET_LINKED_DISPLAYED_ITEMS;
  constructor() {}
}

export type LinkedDisplayedItemsActions = AddOrUpdateLinkedDiplayedItems | RemoveLinkedDiplayedItems | ResetLinkedDisplayedItems;
