<div *ngIf="task" class="tasks-item hol-clickable" (click)="goToTask(task.objectId)">
  <app-hol-list-item
    [item]="task"
    [displayAllTextInItem]="true"
    [nextInfo]="!displayFromHistory ? { nextInfoTime: task.nextInfoTime, nextInfoDone: task.nextInfoDone } : null"
    [attachments]="task.attachments"
    [ngClass]="task.status"
    [displayUser]="false"
    [canUpdateItem]="canMarkAsDone && isHolder"
    (saveNextInfo)="markAsDone($event)"
  >
    <div class="create-infos-addon">
      <div *ngIf="displayFromHistory" class="function-short-title">
        <span [matTooltip]="functionShortTitle">{{ functionShortTitleToDisplay }}</span>
      </div>
      <div class="task-short-title">
        <span class="font14">{{ codeToDisplay }}</span>
        <span class="font14">{{ task.order }}</span>
      </div>
    </div>

    <div class="header-addon hol-spaced-line">
      <app-tag-chips [tags]="tagsToChip" [isShowName]="false"> </app-tag-chips>
    </div>

    <div *ngIf="displayFromHistory && task.status === 'DONE'" class="output-title">
      <span>{{ task.outputTitle }}</span>
    </div>
  </app-hol-list-item>
</div>
