import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HolObject } from '../../models/hol-object';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { nextInfoType } from '../hol-next-info/hol-next-info.component';
import { HolAttachments } from '../../models/hol-attachments.model';

@Component({
  selector: 'app-hol-list-item',
  templateUrl: './hol-list-item.component.html',
  styleUrls: ['./hol-list-item.component.scss'],
})
export class HolListItemComponent implements OnInit {
  @Input() item: HolObject;
  @Input() localHourOnly = false;
  @Input() isReadOnly = false;
  @Input() displayAllTextInItem = false;
  @Input() isHistory = false;
  @Input() fromModule: string;
  @Input() toModule: string;
  @Input() nextInfo: nextInfoType;
  @Input() canUpdateItem = false;
  @Input() userFunctionsList = [];
  @Input() displayUser = true;

  @Input() attachments: HolAttachments = new HolAttachments();

  public isUtc: boolean;
  public userTooltipContent = '';
  @Output() saveNextInfo = new EventEmitter<nextInfoType>();

  constructor(public moduleConfig: ModuleConfigService) {}

  ngOnInit() {
    this.userTooltipContent = this.item.createdBy ? this.item.createdBy.fullName : '';

    if (this.userFunctionsList.length) {
      let userFunctionTitles = '';
      userFunctionTitles += this.userFunctionsList.map(f => f.shortTitle);
      this.userTooltipContent += ' (' + userFunctionTitles + ')';
    }
    this.isUtc = this.isHistory ? true : this.localHourOnly ? false : !this.isReadOnly;

    if (!this.item) {
      throw new Error('HolListItemComponent::item is required');
    }
  }

  markAsDone() {
    this.saveNextInfo.emit(this.nextInfo);
  }
}
