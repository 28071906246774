import { ErpSmsService } from './erp-sms.service';
import { ErpMailService } from './erp-mail.service';
import { Inject, Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { ErpCrisis } from '../models/erp-crisis';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpCrisisDataService } from './erp-crisis-data.service';
import { OptionsService } from '../../common/services/options.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map, take } from 'rxjs/operators';
import { CrisisService } from '../../common/services/crisis.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { FunctionsStoreManager } from '../store/functions/functions.store-manager';
import { RolesService } from '../../common/services/roles.service';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisService extends CrisisService {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('GDCCrisis');

  // tslint:enabled

  constructor(
    protected requestService: RequestService,
    private crisisStoreManager: CrisisStoreManager,
    private commonStoreManager: CommonStoreManager,
    private functionsStoreManager: FunctionsStoreManager,
    private rolesService: RolesService,
    private crisisDataService: ErpCrisisDataService,
    @Inject('UserService') private userService,
    private erpMailService: ErpMailService,
    private erpSmsService: ErpSmsService,
    private optionsService: OptionsService,
    private commonCrisisService: CrisisService,
    @Inject('BreakingNewsService') private breakingNewsService
  ) {
    super(requestService);
  }

  getCrisis(): Promise<ErpCrisis> {
    return super.get().then(c => c as ErpCrisis);
  }

  saveCrisis(crisis: Partial<ErpCrisis>): Promise<ErpCrisis> {
    const parseObject = new this.ParseCrisis({ id: crisis.objectId });
    parseObject.set('mainTitle', crisis.mainTitle);
    parseObject.set('subtitle', crisis.subTitle);
    parseObject.set('crisisTypeId', crisis.crisisTypeId);
    parseObject.set('isTraining', crisis.isTraining);
    parseObject.set('isInPreparation', crisis.isInPreparation);
    parseObject.set('inProgress', crisis.inProgress);
    parseObject.set('clock_departure', crisis.clockDeparture);
    parseObject.set('clock_arrival', crisis.clockArrival);
    parseObject.set('clock_onSite', crisis.clockOnSite);
    if (crisis.acl) {
      parseObject.setACL(crisis.acl);
    }

    return this.requestService.performSaveQuery(parseObject).then(parseData => {
      const bufferCrisis: ErpCrisis = new ErpCrisis(parseData);
      if (crisis.type) {
        bufferCrisis.type = crisis.type;
      }
      if (crisis.objectId) {
        this.crisisStoreManager.updateCrisis(bufferCrisis);
      }
      return bufferCrisis;
    });
  }

  create(crisis: ErpCrisis): BehaviorSubject<string> {
    const createCrisisProgress = new BehaviorSubject<string>('');
    let newCrisis: ErpCrisis;
    this.resetPreviousCrisis().subscribe(
      s => {
        createCrisisProgress.next(s);
      },
      e => {
        createCrisisProgress.error(e);
      },
      () => {
        if (crisis.isInPreparation) {
          this.commonCrisisService.crisisInPreparation = true;
        } else {
          this.commonCrisisService.crisisInCreation = true;
        }
        createCrisisProgress.next('Create new crisis...');
        this.saveCrisis(crisis)
          .then(c => {
            newCrisis = c;
            createCrisisProgress.next('✅<br/>Create crisis data...');
            // Create crisis data
            return this.crisisDataService.saveAllCrisisData(crisis.data, newCrisis.objectId, true);
          })
          .then(crisisData => {
            createCrisisProgress.next('✅<br/>');
            createCrisisProgress.next('<strong>✅ Crisis created !</strong>');
            createCrisisProgress.complete();
            newCrisis.data = crisisData;
          })
          .then(() => {
            if (newCrisis.isInPreparation) {
              this.erpMailService.sendNewCrisisInPreparationEmails(newCrisis);
              this.erpSmsService.sendNewCrisisInPreparationSms(newCrisis);
            } else {
              this.erpMailService.sendNewCrisisEmails(newCrisis);
              this.erpSmsService.sendNewCrisisSms(newCrisis);
            }
            return newCrisis;
          })
          .catch(e => {
            createCrisisProgress.error(e);
          });
      }
    );
    return createCrisisProgress;
  }

  activateByCrisisDirector(crisis): Promise<ErpCrisis> {
    const parseCrisis = new this.ParseCrisis({ id: crisis.objectId });
    parseCrisis.set('isInPreparation', false);
    parseCrisis.set('inProgress', true);
    this.commonCrisisService.crisisInCreation = true;
    return this.requestService.performSaveQuery(parseCrisis).then(data => {
      const newCrisis = new ErpCrisis(data);
      this.erpMailService.sendNewCrisisEmails(newCrisis);
      this.erpSmsService.sendNewCrisisSms(newCrisis);
      return newCrisis;
    });
  }

  closeCrisis(crisis: ErpCrisis): Promise<ErpCrisis> {
    const parseCrisis = new this.ParseCrisis({ id: crisis.objectId });
    parseCrisis.set('isInPreparation', false);
    parseCrisis.set('inProgress', false);
    return this.requestService.performSaveQuery(parseCrisis).then(data => {
      if (this.optionsService.hasToSendMails()) {
        this.erpMailService.sendCrisisOverMail(crisis);
      }
      if (this.optionsService.hasToSendSms()) {
        this.erpSmsService.sendCrisisOverSms(crisis);
      }

      this.breakingNewsService.deleteAllBreakingNews();
      const closedCrisis = new ErpCrisis(data);
      this.crisisStoreManager.updateCrisis(closedCrisis);
      return closedCrisis;
    });
  }

  private resetPreviousCrisis(): BehaviorSubject<string> {
    const resetCrisisProgress = new BehaviorSubject<string>('');
    this.commonStoreManager.crisis
      .pipe(take(1))
      .toPromise()
      .then(crisis => {
        if (crisis) {
          resetCrisisProgress.next('Delete previous crisis data...');
          return this.requestService
            .performCloudCode('purgeCrisisData', null)
            .then(() => {
              resetCrisisProgress.next('✅<br/>Reset functions...');
              return this.requestService.performCloudCode('resetFunctions', { createdAt: crisis.createdAt });
            })
            .then(() => {
              resetCrisisProgress.next('✅<br/>Reset tasks...');
              return this.requestService.performCloudCode('resetTasks', { crisisTypeId: crisis.crisisTypeId, createdAt: crisis.createdAt });
            })
            .then(() => {
              resetCrisisProgress.next('✅<br/>Reset users...');
              return this.requestService.performCloudCode('resetUsers', null);
            })
            .then(() => {
              resetCrisisProgress.next('✅<br/>Reset users functions...');
              return this.requestService.performCloudCode('resetUserFunctions', null);
            })
            .then(() => {
              resetCrisisProgress.next('✅<br/>Reset logbook linked to ECL...');
              return this.requestService.performCloudCode('deleteECLLogbookLinked', null);
            })
            .then(() => {
              resetCrisisProgress.next('✅<br/>Reset decisions linked to ECL...');
              return this.requestService.performCloudCode('deleteECLDecisionsLinked', null);
            })
            .then(() => {
              resetCrisisProgress.next('✅<br/>');
              resetCrisisProgress.complete();
            });
        } else {
          resetCrisisProgress.next('No crisis to reset<br/>');
          resetCrisisProgress.complete();
        }
      })
      .catch(e => {
        resetCrisisProgress.error(e);
      });
    return resetCrisisProgress;
  }

  updateCrisisAcl(crisis: ErpCrisis): Promise<ErpCrisis> {
    const oldCrisis = new ErpCrisis(new this.ParseCrisis({ id: crisis.objectId }));
    const oldCompanies = oldCrisis.companies;
    oldCrisis.parseACL(crisis.acl);
    const newCompanies = oldCrisis.companies.filter(c => !oldCompanies.includes(c));
    if (newCompanies.length) {
      return this.requestService
        .performCloudCode<Parse.Object>('updateCrisisACL', { crisisId: crisis.objectId, acl: crisis.acl })
        .then(parseCrisis => {
          this.functionsStoreManager.functionsCrisisErpState
            .pipe(
              take(1),
              map(fns => fns.filter(f => f.notified).map(f => f.functionId))
            )
            .toPromise()
            .then(functionsIdToNotify => {
              this.erpMailService.sendNewCrisisEmails(crisis, functionsIdToNotify, null, newCompanies);
              this.erpSmsService.sendNewCrisisSms(crisis, functionsIdToNotify, null, newCompanies);
            });
          return new ErpCrisis(parseCrisis);
        });
    } else {
      return Promise.resolve(crisis);
    }
  }
}
