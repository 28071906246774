import { OclGlobalInstructionGroup } from './../../models/ocl-global-instruction-group.model';
import { OclGroupModalComponent } from './../../modals/ocl-group-modal/ocl-group-modal.component';
import { takeUntil } from 'rxjs/operators';
import { OclGroupService } from 'src/app/ocl/services/ocl-group-service/ocl-group.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, Subscription, combineLatest, Subject } from 'rxjs';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { RolesService } from 'src/app/common/services/roles.service';
import { OclGlobalInstructionModalComponent } from '../../modals/ocl-global-instruction-modal/ocl-global-instruction-modal.component';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';
import * as _ from 'lodash';
import { FlyIn } from 'src/app/common/models/hol-animations';
@Component({
  selector: 'app-ocl-global-instruction-box',
  templateUrl: './ocl-global-instruction-box.component.html',
  styleUrls: ['./ocl-global-instruction-box.component.scss'],
  animations: [FlyIn],
})
export class OclGlobalInstructionBoxComponent implements OnInit, OnDestroy {
  @Input() isReadOnly: boolean;
  @Input() localHourOnly: boolean;

  @Input()
  set globalInstructionsInput(value) {
    this._globalInstructionsItems.next(value);
  }

  get globalInstructionsInput() {
    return this._globalInstructionsItems.getValue();
  }

  @Input()
  set globalInstructionsGroupsInput(value) {
    this._globalInstructionsGroups.next(value);
  }

  get globalInstructionsGroupsInput() {
    return this._globalInstructionsGroups.getValue();
  }

  @Input()
  set searchCriterias(value) {
    this._searchCriterias.next(value);
  }

  get searchCriteriasdata() {
    return this._searchCriterias.getValue();
  }
  private _searchCriterias = new BehaviorSubject<string>('');
  private _globalInstructionsItems = new BehaviorSubject<any>([]);
  private _globalInstructionsGroups = new BehaviorSubject<any>([]);

  public searchCriteriasSub: Subscription;
  public globalInstructionsItemsSub: Subscription;
  private ngUnsubscribe: Subject<void> = new Subject();

  public globalInstructionGrouped: OclGlobalInstructionGroup[] = [];
  public globalInstructionItems: OclGlobalInstruction[] = [];
  public groupsFromApi: OclGlobalInstructionGroup[] = [];
  public groupsAvailable: OclGlobalInstructionGroup[] = [];
  public isSetGroups: boolean;
  public enableViewGroup: boolean;
  public loading = false;
  public searchCriteriasForPipe: string;

  public globalInstructionGroupsSub: Subscription;

  public RolesService = RolesService;

  public context = 'global_instruction';

  constructor(private dialog: MatDialog, public moduleConfig: ModuleConfigService, protected groupService: OclGroupService) {}

  ngOnInit() {
    combineLatest(this._globalInstructionsItems, this._globalInstructionsGroups)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([globalInstructionItems, globalInstructionsGroups]) => {
        this.globalInstructionItems = globalInstructionItems ? _.cloneDeep(globalInstructionItems) : [];
        (this.groupsFromApi = globalInstructionsGroups ? globalInstructionsGroups : []),
          (this.groupsAvailable =
            globalInstructionsGroups && globalInstructionsGroups.length
              ? this.groupService.generateGroupAvailable(_.cloneDeep(globalInstructionsGroups), this.globalInstructionItems)
              : []);
        this.globalInstructionGrouped = this.generateGlobalInstructionGrouped(this.groupsAvailable, this.globalInstructionItems);
        if (this.groupsAvailable.length && !this.enableViewGroup) {
          this.enableViewGroup = true;
          this.isSetGroups = true;
        }
        if (!this.groupsAvailable.length && this.enableViewGroup) {
          this.enableViewGroup = false;
          this.isSetGroups = false;
        }
      });

    this.searchCriteriasSub = this._searchCriterias.subscribe(cristerias => {
      this.searchCriteriasForPipe = _.cloneDeep(cristerias);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.searchCriteriasSub.unsubscribe();
  }

  public openAddModal(event): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(OclGlobalInstructionModalComponent, {
      data: {
        isReadOnly: this.isReadOnly,
        localHourOnly: this.localHourOnly,
      },
    });
  }

  trackByFn(index, item) {
    return item.objectId || item.items[0].objectId;
  }

  generateGlobalInstructionGrouped(group, globalInstructions): OclGlobalInstructionGroup[] {
    return _.orderBy(
      this.groupService.generateGroupForDisplay(group, globalInstructions, 'GLOBAL_INSTRUCTION'),
      item => {
        if ((item as OclGlobalInstructionGroup).getMostRecentGlobalInstruction()) {
          return (item as OclGlobalInstructionGroup).getMostRecentGlobalInstruction().createdAt;
        }
        return null;
      },
      'desc'
    );
  }

  public openGroupModal(): void {
    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        globalInstructionItems: this.globalInstructionItems,
        isReadOnly: this.isReadOnly,
        group: null,
        groupsFromApi: this.groupsFromApi,
        type: 'GLOBAL_INSTRUCTION',
      },
    });
    dialogRef.afterClosed().subscribe(group => {
      this.groupService.fetchNewDataGlobalInstructionGroup();
    });
  }
}
