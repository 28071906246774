import { GocModuleConfigService } from './../goc-module-config/goc-module-config.service';
import { Inject, Injectable } from '@angular/core';
import { FltFlightService } from '../../../flt/services/flt-flight.service';
import { groupBy, orderBy } from 'lodash';
import { GocFlight } from '../../models/goc-flight.model';
import { HolTag } from '../../../common/models/hol-tag';
import { RequestService } from '../../../common/services/request.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { GocFlightLogbookService } from '../goc-flight-logbook-service/goc-flight-logbook.service';
import { GocFlightInstructionService } from '../goc-flight-instruction-service/goc-flight-instruction.service';
import { GocFlightInstruction } from '../../models/goc-flight-instruction.model';
import { GocOfficer } from '../../models/goc.officer.model';
import { GocFlightEventService } from '../goc-flight-event-service/goc-flight-event-service';
import { FltUserAirportService } from 'src/app/flt/services/flt-user-airport.service';
import { OclOptionsService } from 'src/app/ocl/services/ocl-options-service/ocl-options.service';
import { GocStopover } from '../../models/goc-stopover.model';
import { GocFlightLogbook } from '../../models/goc-flight-logbook.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { GocOptionsService } from '../goc-options-service/goc-options.service';

@Injectable({
  providedIn: 'root',
})
export class GocFlightService extends FltFlightService<GocFlight> {
  // tslint:disable:variable-name
  protected ParseEvent = Parse.Object.extend('GOCEvents');
  protected ParseEventInfo = Parse.Object.extend('GOCEventInfos');
  protected ParseFlightInstruction = Parse.Object.extend('GOCFlightInstruction');
  protected ParseFlightLogbook = Parse.Object.extend('GOCFlightLogbook');
  protected ParseFlightTag = Parse.Object.extend('GOCFlightTag');
  protected ParseTag = Parse.Object.extend('GOCTag');
  // tslint:enable
  protected constructor(
    protected requestService: RequestService,
    @Inject('UserService') protected userService,
    protected parseMapper: ParseMapperService,
    protected userAirportService: FltUserAirportService,
    protected gocOptionService: GocOptionsService,
    protected commonStoreManager: CommonStoreManager,
    protected flightLogbookService: GocFlightLogbookService,
    protected flightInstructionService: GocFlightInstructionService,
    protected flightEventService: GocFlightEventService,
    protected optionsService: OclOptionsService,
    protected moduleConfig: GocModuleConfigService
  ) {
    super(
      requestService,
      userService,
      parseMapper,
      userAirportService,
      gocOptionService,
      commonStoreManager,
      flightLogbookService,
      flightInstructionService,
      flightEventService,
      moduleConfig
    );
  }
  protected newFlight(parseObject: Parse.Object, tags?: HolTag[]): GocFlight {
    return new GocFlight(parseObject, tags);
  }

  public getStopoverByNaturalKey(naturalKey: string, addToStore: boolean): Promise<GocStopover> {
    const split = naturalKey.split('-');
    const inFlightId = split[2];
    const outFlightId = split[0];
    const inFlightPromise = this.getById(inFlightId);
    const outFlightPromise = this.getById(outFlightId);
    return Promise.all([outFlightPromise, inFlightPromise]).then(res => {
      const stopover = new GocStopover(res[0], res[1]);
      if (addToStore) {
        this.commonStoreManager.updateCurrentStopover(stopover);
      }
      return stopover;
    });
  }

  public getGOCStopOversFromFlights(flights: Array<GocFlight>, airports): Array<GocStopover> {
    const groupedFlights = _.groupBy(flights, 'registration');
    const stopOvers = [];
    for (const key in groupedFlights) {
      if (groupedFlights.hasOwnProperty(key)) {
        const group = _.orderBy(groupedFlights[key], 'std');
        if (group.length > 1) {
          for (let i = 1; i < group.length; i++) {
            const inFlight = group[i - 1];
            const outFlight = group[i];
            if (!airports || !airports.length || airports.find(a => a === outFlight.destination)) {
              try {
                stopOvers.push(new GocStopover(inFlight as any, outFlight as any));
              } catch (e) {
                // console.warn('Consecutive flights without common airport...', outFlight, inFlight);
              }
            }
          }
        }
      }
    }

    return _.orderBy(stopOvers, stopover => {
      return stopover.outFlight.std;
    });
  }

  public getByTimeSlots(forceToRefresh: boolean, fromDate?: moment.Moment, toDate?: moment.Moment): Promise<any[]> {
    let from = fromDate; // more far in the past
    let to = toDate; // closer in the past or today
    to = (to && to.clone().endOf('day')) || moment.utc();
    if (to.isAfter(moment().utc())) {
      to = moment.utc();
    }
    from = (from && from.clone().startOf('day')) || to.clone().subtract(1, 'days').startOf('day');
    return this.userAirportService.getUserAirports().then(airports => {
      let flightQuery;
      if (airports && airports.length) {
        const depQuery = new Parse.Query(this.ParseFlight);
        depQuery.containedIn('departure', airports);
        const arrQuery = new Parse.Query(this.ParseFlight);
        arrQuery.containedIn('destination', airports);
        flightQuery = Parse.Query.or(depQuery, arrQuery);
      } else {
        flightQuery = new Parse.Query(this.ParseFlight);
      }
      flightQuery.greaterThanOrEqualTo('std', from.toDate());
      flightQuery.lessThanOrEqualTo('std', to.toDate());
      flightQuery.notEqualTo('status', 'C');
      flightQuery.include('opsOfficer');
      flightQuery.include('tags');
      flightQuery.ascending('std');
      return this.requestService.performFindQuery(flightQuery).then(async parseFlights => {
        const flights = parseFlights.map(f => this.newFlight(f));
        await this.retrieveTags(flights);
        return flights;
      });
    });
  }

  public getAllFromYesterday(filterDataStartDate?: Date): Promise<GocFlight[]> {
    let today: Date;
    if (this.moduleConfig.config.canChooseDataStartDate && filterDataStartDate instanceof Date && filterDataStartDate !== null) {
      today = filterDataStartDate;
    } else {
      today = new Date();
    }

    const stopoverThreshold = this.gocOptionService.getStopoverThreshold();
    // return new Promise((resolve, reject) => {
    return this.userAirportService.getUserAirports().then(airports => {
      let query;
      if (airports && airports.length) {
        const depQuery = new Parse.Query(this.ParseFlight);
        depQuery.containedIn('departure', airports);
        const arrQuery = new Parse.Query(this.ParseFlight);
        arrQuery.containedIn('destination', airports);
        query = Parse.Query.or(depQuery, arrQuery);
      } else {
        query = new Parse.Query(this.ParseFlight);
      }
      query.greaterThanOrEqualTo('std', moment.utc(today).startOf('day').subtract(1, 'day').toDate());
      query.lessThanOrEqualTo('std', moment.utc(today).endOf('day').add(1, 'day').toDate());
      // query.greaterThanOrEqualTo('std', moment('2020-07-03T12:00:00.000Z').utc().startOf('day').subtract(1, 'day').toDate());
      // query.lessThanOrEqualTo('std', moment('2020-07-03T12:00:00.000Z').utc().endOf('day').add(1, 'day').toDate());
      query.ascending('std');
      query.notEqualTo('status', 'C');
      query.include('gocOfficer');
      query.limit(1000);

      return this.requestService.performFindQuery(query).then(async results => {
        const flights = results.map(f => this.newFlight(f));
        await this.retrieveTags(flights);
        await this.retrieveSummary(flights);
        this.commonStoreManager.updateFlights(flights);
        const gocStopovers = await this.getGOCStopOversFromFlights(flights, airports);
        this.commonStoreManager.updateStopovers(gocStopovers);
        return flights;
      });
    });
  }
  public async retrieveLastLogbook(flights: GocFlight[]): Promise<GocFlight[]> {
    if (flights.length && !flights[0].logbooks) {
      const parseFlights = flights.map(f => {
        return new this.ParseFlight({ id: f.objectId });
      });
      const lastlogbookQuery = new Parse.Query(this.ParseFlightLogbook);
      lastlogbookQuery.containedIn('flight', parseFlights);
      lastlogbookQuery.descending('createdAt');
      lastlogbookQuery.include('logbook');
      lastlogbookQuery.include('decision');
      lastlogbookQuery.include('occLogbook');
      lastlogbookQuery.include('createdBy');

      return this.requestService.performFindQuery(lastlogbookQuery).then(parseLogs => {
        const groupedLogs = groupBy<Parse.Object>(parseLogs, pi => {
          return pi.get('flight').id;
        });

        flights.forEach(f => {
          if (groupedLogs[f.objectId]) {
            const lastLog = groupedLogs[f.objectId][0];
            f.lastLogbookEntry = lastLog ? new GocFlightLogbook(lastLog) : null;
          } else {
            f.lastLogbookEntry = null;
          }
        });
        return Promise.resolve(flights);
      });
    } else {
      flights.forEach(f => {
        if (f.logbooks && f.logbooks.length) {
          const lastLog = orderBy(f.logbooks, (l: GocFlightLogbook) => l.createdAt, 'desc')[0];
          f.lastLogbookEntry = lastLog ? lastLog : null;
        } else {
          f.lastLogbookEntry = null;
        }
      });
    }
  }

  public async retrieveSummary(flights: GocFlight[]): Promise<GocFlight[]> {
    await this.retrieveLastLogbook(flights);
    await this.retrieveCriticalInstruction(flights);
    return Promise.resolve(flights);
  }

  public async retrieveCriticalInstruction(flights: GocFlight[]): Promise<GocFlight[]> {
    // flights = this.retrieveLastLogbook(flights)
    if (flights.length && !flights[0].instructions) {
      const parseFlights = flights.map(f => {
        return new this.ParseFlight({ id: f.objectId });
      });
      const instructionsQuery = new Parse.Query(this.ParseFlightInstruction);
      instructionsQuery.containedIn('flight', parseFlights);
      instructionsQuery.notEqualTo('done', true);
      instructionsQuery.notEqualTo('archived', true);

      return this.requestService.performFindQuery(instructionsQuery).then(parseInstructions => {
        const groupedInstructions = groupBy<Parse.Object>(parseInstructions, pi => {
          return pi.get('flight').id;
        });
        flights.forEach(f => {
          if (groupedInstructions[f.objectId]) {
            const mostCritical = orderBy<Parse.Object>(groupedInstructions[f.objectId], i => i.get('nextInfoTime')).filter(
              el => el.get('done') !== true
            )[0];
            f.mostCriticalInstructionTime = mostCritical.get('nextInfoTime');
            f.hasUncheckedInstructions = !!groupedInstructions[f.objectId].length;

            const allInstructionsArr = orderBy<Parse.Object>(groupedInstructions[f.objectId], i => i.get('nextInfoTime')).filter(
              el => el.get('station') !== f.departure
            );
            const allInstructionsCountArrCheckedCount = allInstructionsArr.filter(el => el.get('done') === true).length;
            const allInstructionsCountArrNotCheckedCount = allInstructionsArr.filter(el => el.get('done') !== true).length;
            if (allInstructionsCountArrCheckedCount === allInstructionsArr.length) {
              f.doneInstructionSatusArr = 'ALL_DONE';
            } else if (allInstructionsCountArrNotCheckedCount === allInstructionsArr.length) {
              f.doneInstructionSatusArr = 'NO_ONE_DONE';
            } else {
              f.doneInstructionSatusArr = 'AT_LEAST_ONE_DONE';
            }

            const allInstructionsDep = orderBy<Parse.Object>(groupedInstructions[f.objectId], i => i.get('nextInfoTime')).filter(
              el => el.get('station') !== f.destination
            );
            const allInstructionsCountDepCheckedCount = allInstructionsDep.filter(el => el.get('done') === true).length;
            const allInstructionsCountDepNotCheckedCount = allInstructionsDep.filter(el => el.get('done') !== true).length;
            if (allInstructionsCountDepCheckedCount === allInstructionsDep.length) {
              f.doneInstructionSatusDep = 'ALL_DONE';
            } else if (allInstructionsCountDepNotCheckedCount === allInstructionsDep.length) {
              f.doneInstructionSatusDep = 'NO_ONE_DONE';
            } else {
              f.doneInstructionSatusDep = 'AT_LEAST_ONE_DONE';
            }
          } else {
            f.mostCriticalInstructionTime = null;
            f.hasUncheckedInstructions = false;
            f.doneInstructionSatusDep = 'ALL_DONE';
            f.doneInstructionSatusArr = 'ALL_DONE';
          }
        });

        return Promise.resolve(flights);
      });
    } else {
      flights.forEach(f => {
        if (f.instructions && f.instructions.length) {
          const uncheckedInstructions = f.instructions.filter(i => !i.done);
          const mostCritical = orderBy(uncheckedInstructions, (i: GocFlightInstruction) => i.nextInfoTime)[0];
          f.mostCriticalInstructionTime = mostCritical ? mostCritical.nextInfoTime : null;
          f.hasUncheckedInstructions = !!uncheckedInstructions.length;

          const allInstructionsArr = f.instructions.filter(el => el.station !== f.departure);

          const allInstructionsCountArrCheckedCount = allInstructionsArr.filter(el => el.done === true).length;
          const allInstructionsCountArrNotCheckedCount = allInstructionsArr.filter(el => el.done !== true).length;
          if (allInstructionsCountArrCheckedCount === allInstructionsArr.length) {
            f.doneInstructionSatusArr = 'ALL_DONE';
          } else if (allInstructionsCountArrNotCheckedCount === allInstructionsArr.length) {
            f.doneInstructionSatusArr = 'NO_ONE_DONE';
          } else {
            f.doneInstructionSatusArr = 'AT_LEAST_ONE_DONE';
          }

          const allInstructionsDep = f.instructions.filter(el => el.station !== f.destination);
          const allInstructionsCountDepCheckedCount = allInstructionsDep.filter(el => el.done === true).length;
          const allInstructionsCountDepNotCheckedCount = allInstructionsDep.filter(el => el.done !== true).length;
          if (allInstructionsCountDepCheckedCount === allInstructionsDep.length) {
            f.doneInstructionSatusDep = 'ALL_DONE';
          } else if (allInstructionsCountDepNotCheckedCount === allInstructionsDep.length) {
            f.doneInstructionSatusDep = 'NO_ONE_DONE';
          } else {
            f.doneInstructionSatusDep = 'AT_LEAST_ONE_DONE';
          }
        } else {
          f.mostCriticalInstructionTime = null;
          f.hasUncheckedInstructions = false;
          f.doneInstructionSatusDep = 'ALL_DONE';
          f.doneInstructionSatusArr = 'ALL_DONE';
        }
      });
    }
  }
  // public async retrieveCriticalInstructionDep(flights: GocFlight[]): Promise<GocFlight[]> {
  //   if (flights.length && !flights[0].instructions) {
  //     const parseFlights = flights.map(f => {
  //       return new this.ParseFlight({ id: f.objectId });
  //     });
  //     const instructionsQuery = new Parse.Query(this.ParseFlightInstruction);
  //     instructionsQuery.containedIn('flight', parseFlights);
  //     instructionsQuery.notEqualTo('done', true);
  //     instructionsQuery.notEqualTo('archived', true);

  //     return this.requestService.performFindQuery(instructionsQuery).then(parseInstructions => {
  //       const groupedInstructions = groupBy<Parse.Object>(parseInstructions, pi => {
  //         return pi.get('flight').id;
  //       });
  //       flights.forEach(f => {
  //         if (groupedInstructions[f.objectId]) {
  //           const mostCritical = orderBy<Parse.Object>(groupedInstructions[f.objectId], i => i.get('nextInfoTime'))[0];
  //           f.mostCriticalInstructionTimeDep = mostCritical.get('nextInfoTime');
  //           f.hasUncheckedInstructionsDep = !!groupedInstructions[f.objectId].length;
  //         } else {
  //           f.mostCriticalInstructionTimeDep = null;
  //           f.hasUncheckedInstructionsDep = false;
  //         }
  //       });

  //       return Promise.resolve(flights);
  //     });
  //   } else {
  //     flights.forEach(f => {
  //       if (f.instructions && f.instructions.length) {
  //         const uncheckedInstructions = f.instructions.filter(i => !i.done && i.station !== f.destination);
  //         const mostCritical = orderBy(uncheckedInstructions, (i: GocFlightInstruction) => i.nextInfoTime)[0];
  //         f.mostCriticalInstructionTime = mostCritical ? mostCritical.nextInfoTime : null;
  //         f.hasUncheckedInstructions = !!uncheckedInstructions.length;
  //       } else {
  //         f.mostCriticalInstructionTime = null;
  //         f.hasUncheckedInstructions = false;
  //       }
  //     });
  //   }
  // }

  public saveGocOfficer(flight: GocFlight, gocOfficerId: string) {
    const parseObject = new this.ParseFlight({ id: flight.objectId });
    parseObject.set('gocOfficer', gocOfficerId ? new this.ParseGOCOfficer({ id: gocOfficerId }) : {});
    return this.requestService.performSaveQuery(parseObject).then(res => {
      flight.gocOfficer = new GocOfficer(res.get('gocOfficer'));
      this.commonStoreManager.updateOneFlight(flight);
      this.commonStoreManager.updateCurrentFlight(flight);
      return flight;
    });
  }
}
