import { Action } from '@ngrx/store';
import { ErpLayout } from '../../erp.model';

export enum LayoutActionTypes {
  SET_TASKS_FILTER = '[ERP LAYOUT] Set Task Filter',
  SET_SEARCH_VALUE_FILTER = '[ERP LAYOUT] Set Search Value Filter',
}

export class SetTaskFilter implements Action {
  readonly type = LayoutActionTypes.SET_TASKS_FILTER;
  constructor(public payload: Partial<ErpLayout>) {}
}

export class SetSearchValueFilter implements Action {
  readonly type = LayoutActionTypes.SET_SEARCH_VALUE_FILTER;
  constructor(public payload: string) {}
}

export type LayoutActions = SetTaskFilter | SetSearchValueFilter;
