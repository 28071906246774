import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { HolNotifyFunction } from './../../models/hol-notification.model';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { HelperService } from 'src/app/common/services/helper.service';
import { RolesService } from 'src/app/common/services/roles.service';
import { Component, Input, OnInit, Output, OnDestroy, EventEmitter, AfterViewInit, AfterViewChecked } from '@angular/core';
import { HolNotification } from '../../models/hol-notification.model';

@Component({
  selector: 'app-notification-table',
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss'],
})
export class NotificationTableComponent implements OnInit, OnDestroy {
  RolesService = RolesService;
  private _notifications = new BehaviorSubject<HolNotification[]>([]);
  @Input()
  set notifications(value) {
    this._notifications.next(value);
  }
  get notifications() {
    return this._notifications.getValue();
  }
  @Input() notifyFunction: HolNotifyFunction = undefined;
  @Input()
  set acl(value) {
    this._acl.next(value);
  }
  private _acl = new BehaviorSubject<Parse.ACL>(undefined);
  aclSub: Subscription;
  companiesRolesFilter: string[];
  @Input() color = 'primary';
  @Input() type = '';
  @Output() saveNotifications = new EventEmitter<HolNotification[]>();
  @Output() saveNotifyFunction = new EventEmitter<HolNotifyFunction>();

  constructor(public helperService: HelperService, public moduleConfig: ModuleConfigService) {}

  ngOnInit() {
    this.aclSub = this._acl.subscribe(acl => {
      if (acl) {
        this.companiesRolesFilter = this.helperService.parseACL(acl);
      } else {
        this.companiesRolesFilter = RolesService.companiesRolesFilter;
      }

      this.notifications = this.notifications.map(notif => {
        if (!this.companiesRolesFilter || !this.companiesRolesFilter.length) {
          notif.disabled = true;
        } else {
          if (!notif.companies || !notif.companies.length) {
            notif.disabled = false;
          } else {
            const availableCompanies = notif.companies.filter(c => this.companiesRolesFilter.indexOf(c) > -1);
            notif.disabled = availableCompanies.length !== 0 ? false : true;
          }
        }
        if (notif.disabled) {
          notif.sendBySms = false;
          notif.sendByMail = false;
        }
        return notif;
      });
    });
  }

  callSaveNotifications() {
    this.saveNotifications.emit(this.notifications);
  }

  callSaveNotifyFunction() {
    this.saveNotifyFunction.emit(this.notifyFunction);
  }

  ngOnDestroy(): void {
    this.aclSub.unsubscribe();
  }
}
