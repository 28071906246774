<div class="new-holis-theme">
  <ng-container *ngIf="showTitle">
    <label *ngIf="!isInHeader && !readOnly && !viewOnly">
      {{ 'COMMON.SELECT_TAGS' | translate }}
    </label>
  </ng-container>

  <div class="tag-selector" [ngStyle]="{ 'flex-direction': $rootScope.isPhone ? 'column' : 'row' }">
    <div class="filters-container" [ngClass]="{ 'mt-1': $rootScope.isPhone }">
      <ng-container *ngFor="let tag of tags; trackBy: trackByFunction">
        <button
          *ngIf="tag.name && tag.name.length"
          (click)="!readOnly && !viewOnly ? toggleTag(tag) : $event.preventDefault()"
          [disabled]="readOnly || isDefaultag(tag)"
          [ngClass]="{
            hidden: readOnly && !isSelectedTag(tag) && !isDefaultag(tag),
            'mb-1': $rootScope.isPhone,
            'mb-0': !$rootScope.isPhone
          }"
          [ngStyle]="buttonStyle(tag)"
          aria-label="tag.name"
          class="btn"
          mat-raised-button
        >
          <span class="tag-name">{{ tag.name }}</span>
        </button>
      </ng-container>
    </div>
    <div *ngIf="addForm && moduleConfig.config.canCreateTags" class="addForm">
      <button (click)="addTags()" class="open-tag-add">
        <span>+</span>
      </button>
      <form *ngIf="showInput" [formGroup]="tagForm" (ngSubmit)="onSubmitAddTag()" class="tag-add">
        <input
          class="name"
          formControlName="name"
          placeholder="{{ moduleConfig.config.translateKey + '.DASHBOARD.PLACEHOLDER_TAGS' | translate }}"
          type="text"
        />
        <input
          [cpOutputFormat]="'hex'"
          [cpPosition]="'bottom'"
          [cpPresetColors]="['#fff', '#2889e9']"
          [style.background]="tagForm.get('color').value"
          [(colorPicker)]="tagForm.get('color').value"
          (colorPickerChange)="tagForm.get('color').setValue($event)"
          class="color"
          readonly
        />
        <button mat-button [disabled]="!tagForm.valid" class="cta-add-tag" type="submit">{{ 'COMMON.ADD' | translate }}</button>
        <button (click)="cancelAddTag()" mat-button>{{ 'COMMON.CANCEL' | translate }}</button>
      </form>
    </div>
    <!-- <button class="select-all-btn" *ngIf="!isInHeader && showToggleAll()" (click)="setAllTag()" mat-button="" aria-label="Select all tag">
      {{ 'COMMON.BUTTON.SELECT_ALL' | translate }}
    </button> -->
    <div class="uncheck-all-btn" *ngIf="isInHeader && showUntoggleAll() && !$rootScope.isPhone" (click)="unsetAllTag()">
      <img src="/assets/images/close.svg" class="icon" />
    </div>
  </div>
</div>
