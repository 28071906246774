import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { HolRole } from '../../models/hol-role';
import { AclFilterRole, RolesService } from '../../services/roles.service';
import { MatButtonToggleGroup } from '@angular/material';
import { HolObject } from '../../models/hol-object';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-acl-filter',
  templateUrl: './acl-filter.component.html',
  styleUrls: ['./acl-filter.component.scss'],
})
export class AclFilterComponent implements OnInit {
  @Input() mode: 'single' | 'multi' = 'multi';
  @Input() module = 'OCC';
  roles: AclFilterRole[];
  @ViewChild('group') btnGroup: MatButtonToggleGroup;
  RolesService = RolesService;
  availableCompaniesRoles: AclFilterRole[] = [];
  private previousValue: AclFilterRole[];

  constructor(private requestService: RequestService, private rolesService: RolesService) {}

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.previousValue = this.btnGroup.value;
      this.btnGroup.value = this.roles.filter(r => !!(r.userWriteRoles.length || r.userReadRoles.length));
      this.onChange();
    } else if (this.previousValue) {
      this.btnGroup.value = this.previousValue;
      this.onChange();
    }
  }

  private _limitTo: HolObject = null;

  @Input()
  get limitTo(): HolObject {
    return this._limitTo;
  }

  set limitTo(value: HolObject) {
    this._limitTo = value;
    if (
      this.roles &&
      (!this.selectedCompanyRole ||
        (value && value.companies && value.companies.length && !value.companies.includes(this.selectedCompanyRole.company)))
    ) {
      this.selectedCompanyRole = this.roles.find(this.isDefaultRole.bind(this));
    }
  }

  // For single mode
  private _selectedCompanyRole: AclFilterRole = null;

  @Input()
  get selectedCompanyRole(): AclFilterRole {
    return this._selectedCompanyRole;
  }

  set selectedCompanyRole(value: AclFilterRole) {
    this._selectedCompanyRole = value;
    if (value) {
      this.rolesService.setCompanyRolesFilter([value.company]);
    }
  }

  async ngOnInit() {
    this.roles = await this.rolesService.getUserCompanyRolesByUniverse(this.module);
    if (this.mode === 'multi') {
      this.availableCompaniesRoles = this.roles.filter(role => role.userReadRoles.length || role.userWriteRoles.length);
      const availableCompanies = this.availableCompaniesRoles.map(role => role.company);
      this.rolesService.setCompanyRolesFilter(availableCompanies);
    } else {
      if (RolesService.companiesRolesFilter.length) {
        this.selectedCompanyRole = this.roles.find(r => RolesService.companiesRolesFilter[0] === r.company);
      } else {
        this.selectedCompanyRole = this.roles.find(this.isDefaultRole.bind(this));
      }
    }
  }

  onChange() {
    if (this.mode === 'multi') {
      const companies = this.btnGroup.value && this.btnGroup.value.map(r => r.company);
      this.rolesService.setCompanyRolesFilter(companies);
    }
  }

  // https://gist.github.com/jedfoster/7939513
  mixColors(color1, color2, weight) {
    return HelperService.mixColors(color1, color2, weight);
  }

  isSelected(role: AclFilterRole) {
    return this.btnGroup.value && this.btnGroup.value.indexOf(role) > -1;
  }

  toggleSelectAll() {
    if (this.btnGroup.value.length === this.availableCompaniesRoles.length) {
      this.btnGroup.value = [];
    } else {
      this.btnGroup.value = this.availableCompaniesRoles;
    }
    this.onChange();
  }

  private isDefaultRole(r) {
    return (
      (!!r.userReadRoles.length || !!r.userWriteRoles.length) &&
      (!this.limitTo || !this.limitTo.companies || !this.limitTo.companies.length || this.limitTo.companies.includes(r.company))
    );
  }
}
