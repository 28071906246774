import { OclGlobalInstruction } from './../../../ocl/models/ocl-global-instruction.model';
import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash';
import { OclLogbook } from 'src/app/ocl/models/ocl-logbook.model';
import { HolEvent } from '../../models/hol-event';
import { OclDecision } from 'src/app/ocl/models/ocl-decision.model';

@Pipe({
  name: 'occSearchDashboard',
})
export class OccSearchDashboardPipe implements PipeTransform {
  public transform(value: any, searchCriterias?: string, context?: string, scenarioId?: string): any {
    const searchCriteriasArray = searchCriterias.length > 0 ? this.sanitize(this.stringToArray(searchCriterias)) : [];
    if (!searchCriteriasArray.length && !scenarioId) {
      return value;
    }
    if (context === 'decision') {
      let desicionsAfterSearch = cloneDeep(value);
      searchCriteriasArray.forEach(searchTerm => {
        desicionsAfterSearch = desicionsAfterSearch.filter(group => {
          group.items = group.items.filter((decision: OclDecision) => {
            const availableDataForSearch = `${decision.message} ${decision.createdBy.monogram()}`;
            if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
              return decision;
            }
          });
          if (group.items.length) {
            return group;
          }
        });
      });
      return desicionsAfterSearch;
    }
    if (context === 'logbook') {
      let logbooksAfterSearch = cloneDeep(value);
      searchCriteriasArray.forEach(searchTerm => {
        logbooksAfterSearch = logbooksAfterSearch.filter(group => {
          group.items = group.items.filter((logbook: OclLogbook) => {
            const availableDataForSearch = `${logbook.text} ${logbook.createdBy.monogram()}`;
            if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
              return logbook;
            }
          });
          if (group.items.length) {
            return group;
          }
        });
      });
      return logbooksAfterSearch;
    }
    if (context === 'event') {
      let eventsAfterSearch = cloneDeep(value);
      if (!searchCriteriasArray.length && scenarioId) {
        eventsAfterSearch = this.filterEvents(eventsAfterSearch, '', scenarioId);
        return eventsAfterSearch;
      }
      searchCriteriasArray.forEach(searchTerm => {
        eventsAfterSearch = this.filterEvents(eventsAfterSearch, searchTerm, scenarioId);
      });
      return eventsAfterSearch;
    }
    if (context === 'global_instruction') {
      let globalInstructionsAfterSearch = cloneDeep(value);
      searchCriteriasArray.forEach(searchTerm => {
        globalInstructionsAfterSearch = globalInstructionsAfterSearch.filter((globalInstruction: OclGlobalInstruction) => {
          console.log(globalInstruction.createdBy);
          const availableDataForSearch = `${globalInstruction.description} ${globalInstruction.createdBy.monogram()}`;
          if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
            return globalInstruction;
          }
        });
      });
      return globalInstructionsAfterSearch;
    }
    return value;
  }

  public filterEvents(eventsAfterSearch: any, searchTerm: string, scenarioId: string): any {
    return eventsAfterSearch.filter(tupleEvent => {
      tupleEvent.items = tupleEvent.items.filter((eventInGroup: HolEvent) => {
        if (scenarioId && scenarioId !== eventInGroup.scenario.objectId) {
          return null;
        }
        const availableDataForSearch = `${eventInGroup.description}
                ${eventInGroup.createdBy ? eventInGroup.createdBy.monogram() : ''}
                ${
                  eventInGroup.getNextInfo()
                    ? eventInGroup.getNextInfo().message +
                      `${eventInGroup.getNextInfo().createdBy ? eventInGroup.getNextInfo().createdBy.monogram() : ''}`
                    : ''
                }`;
        if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
          return eventInGroup;
        }
      });
      if (tupleEvent.items.length) {
        return tupleEvent;
      }
    });
  }

  public stringToArray(setence): any {
    return setence.trim().split(' ');
  }

  public sanitize(terms: string[]): string[] {
    return terms.map(term => term.toLowerCase());
  }
}
