import { Component, Inject, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TimeOrDayPipe } from 'src/app/common/pipes/time-or-day/time-or-day.pipe';
import { TranslatePipe } from 'src/app/common/pipes/translate/translate.pipe';
import { SystemNotificationService } from 'src/app/common/services/system-notification.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OclLogBooksStoreManager } from '../../store/logbooks/ocl-log-books-store-manager.service';
import { OclLinkedDisplayedItemsStoreManager } from '../../store/linked-display-items/ocl-linked-displayed-items-store-manager.service';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { OclDecision } from '../../models/ocl-decision.model';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { MatDialog } from '@angular/material';
import { OclDecisionGroup } from '../../models/ocl-decision-group.model';
import { OclDecisionService } from '../../services/ocl-decision-service/ocl-decision.service';
import { OclNotificationsService } from '../../services/ocl-notifications-service/ocl-notifications.service';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclLinksHelperService } from '../../services/ocl-links-helper-service/ocl-links-helper.service';
import { OCL_DECISIONS_MODAL_TOKEN } from '../../modals/ocl-decisions-modal/ocl-decisions-modal.token';

@Component({
  selector: 'app-ocl-decisions-list-item',
  templateUrl: './ocl-decisions-list-item.component.html',
  styleUrls: ['./ocl-decisions-list-item.component.scss'],
})
export class OclDecisionsListItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isReadOnly;
  @Input() localHourOnly;
  @Input() events;
  @Input() item: OclDecision;
  @Input() linkedIds;
  @Input() logBookItems;
  @Input() modeLinkIsActivate;
  @Input() decisionGroupsInput: OclDecisionGroup[];
  @Input() airportsCode;
  @Input() isHistory = false;

  notifications: HolNotification[];

  public timerId;
  public isUtc: boolean;
  public isTodoConfigActivated = false;
  public linkedUid: string;
  public randomColor: string;
  private elapsed = false;

  constructor(
    protected dialog: MatDialog,
    public moduleConfig: ModuleConfigService,
    protected translatePipe: TranslatePipe,
    protected systemNotificationService: SystemNotificationService,
    protected timeOrDayPipe: TimeOrDayPipe,
    protected decisionsService: OclDecisionService,
    protected flightsStatusService: OclFlightStatusService,
    @Inject('CONSTANTS') protected CONSTANTS,
    @Inject('localStorage') protected localStorage,
    @Inject('CommonService') public commonService,
    protected linksHelperService: OclLinksHelperService,
    protected occLinkedDisplayedItemsStoreManager: OclLinkedDisplayedItemsStoreManager,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    protected occEventsStoreManager: OclEventsStoreManager,
    protected notificationsService: OclNotificationsService,
    @Inject(OCL_DECISIONS_MODAL_TOKEN) protected decisionModal
  ) {
    this.isTodoConfigActivated = this.moduleConfig.config.activateTODO;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && changes.item.currentValue && changes.item.currentValue.linkedData && changes.item.currentValue.linkedData) {
      this.linkedUid = changes.item.currentValue.linkedData.linkedUid;
    } else if (
      changes.item &&
      changes.item.previousValue &&
      changes.item.previousValue.linkedData &&
      changes.item.previousValue.linkedData &&
      changes.item.currentValue &&
      !changes.item.currentValue.linkedData
    ) {
      this.linkedUid = undefined;
    }
  }

  ngOnInit(): void {
    this.notificationsService.getAll().then(notif => (this.notifications = notif));

    this.isUtc = this.localHourOnly ? false : !this.isReadOnly;
    this.elapsed = this.isElapsed(this.item.nextInfoTime);
    if (!this.isReadOnly && this.item.nextInfoTime && !this.elapsed) {
      this.timerId = setInterval(() => {
        if (this.isElapsed(this.item.nextInfoTime) && !this.elapsed) {
          this.elapsed = true;
          this.sendNotification(this.item);
          clearInterval(this.timerId);
        }
      }, 5000);
    }
  }

  ngOnDestroy(): void {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  public openDetailModal(event, item): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.decisionModal, {
      data: {
        notifications: this.notifications,
        events: this.events,
        logBookItems: this.logBookItems,
        isUtc: !this.localHourOnly,
        decision: _.cloneDeep(item),
        isReadOnly: this.isReadOnly,
        modeLinkIsActivate: this.modeLinkIsActivate,
        airportsCode: this.airportsCode,
        itemComponent: OclDecisionsListItemComponent,
        isHistory: this.isHistory,
      },
    });
    dialogRef.afterClosed().subscribe(decision => {
      if (decision) {
        this.flightsStatusService.setNeedsUpdate(true);
      }
    });
  }

  public showLinkedItems($event): void {
    $event.stopPropagation();
    this.moduleConfig.setLoadingItemsLinked();
    const bufferLinkedUid = 'LinkFrom_' + this.item.objectId;

    if (!this.item.linkedData || !this.item.linkedData.linkedUid) {
      this.linksHelperService.getLinkFromDecision(this.item.objectId).then(data => {
        this.randomColor = data[0];
        const linkedItemsToDisplay = data[1];
        const logBooksToUpdate = data[2];
        const eventsToUpdate = data[3];
        this.occLinkedDisplayedItemsStoreManager.addOrUpdateLinkedDiplayedItems(bufferLinkedUid, this.randomColor, linkedItemsToDisplay);
        if (eventsToUpdate.length) {
          this.occEventsStoreManager.updateEventsFromLinkedValue(eventsToUpdate, bufferLinkedUid);
        }
        if (logBooksToUpdate.length) {
          this.occLogBooksStoreManager.updateLogBooksFromLinkedValue(logBooksToUpdate, bufferLinkedUid);
        }
        this.moduleConfig.unsetLoadingItemsLinked();
      });
    } else {
      this.occLinkedDisplayedItemsStoreManager.removeLinkedDiplayedItems(bufferLinkedUid, this.randomColor);
      this.occEventsStoreManager.removeEventsFromLinkedValue(bufferLinkedUid);
      this.occLogBooksStoreManager.removeLogBooksFromLinkedValue(bufferLinkedUid);
      this.linkedUid = undefined;
      this.moduleConfig.unsetLoadingItemsLinked();
    }
  }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }

  public markAsDone(item, $event) {
    $event.stopPropagation();
    this.decisionsService.markAsDone(item, !item.done).then(eventInfo => {
      _.extend(item, eventInfo);
      this.flightsStatusService.setNeedsUpdate(true);
    });
  }

  public sendNotification(decision) {
    let title = 'Decision ';
    title += this.timeOrDayPipe.transform(decision.createdAt, !this.isReadOnly) + ' ';
    title += decision.message;
    const message = this.translatePipe.transform('COMMON.NOTIFICATIONS.REASON.NEXT_INFO_ELAPSED');
    this.systemNotificationService.tryToNotify(title, message, true);
  }

  public getLastDisplayTime(): Date {
    return this.localStorage.getOccDashboardLastDisplayTime() || new Date();
  }

  public setLastDisplayTime(): void {
    this.localStorage.setOccDashboardLastDisplayTime(new Date());
  }
}
