import { Injectable } from '@angular/core';

export class ModuleConfig {
  localHours = false;
  showLinkToFlightOcc = true;
  moduleName = '';
  forceEventChecklistActivation = false;
  forceEventNotification = false;
  activateEventNotification = false;
  canCreateItemsFromLogbook = true;
  canCreateTags = false;
  logbooksOrder = 1;
  eventsOrder = 2;
  decisionsOrder = 3;
  flightsOrder = 4;
  canActivateEclModule = false;
  translateKey = '';
  canEditNi = false;
  canOpenFlight = false;
  multipleFileEnabled = true;
  scenariosByGroup = false;
  rootPath = '';
  canChooseDataStartDate = false;
  displayToGoc = false;
  displayToERP = false;
  activateTODO = false;
}

@Injectable({
  providedIn: 'root',
})
export class ModuleConfigService {
  public config: ModuleConfig = new ModuleConfig();
  public loadingItemsLinked: boolean;
  public displayAllTextInItem: boolean;

  public constructor() {}

  public setLoadingItemsLinked(): void {
    this.loadingItemsLinked = true;
  }

  public unsetLoadingItemsLinked(): void {
    this.loadingItemsLinked = false;
  }

  public toggleDisplayAllTextInItem(): void {
    this.displayAllTextInItem = !this.displayAllTextInItem;
  }
}
