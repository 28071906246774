import { cloneDeep } from 'lodash';
import { ErpLayout } from '../../erp.model';
import { LayoutActions, LayoutActionTypes } from './layout.action';

const layoutInitialState = {
  tasksFilterFunction: 'ALL',
  tasksFilterStatus: [],
  searchValueFilter: '',
};
export function LayoutReducer(state: ErpLayout = layoutInitialState, action: LayoutActions): ErpLayout {
  switch (action.type) {
    case LayoutActionTypes.SET_TASKS_FILTER:
      return {
        ...cloneDeep(state),
        ...action.payload,
      };
      break;
    case LayoutActionTypes.SET_SEARCH_VALUE_FILTER:
      return {
        ...cloneDeep(state),
        searchValueFilter: action.payload,
      };
      break;
    default:
      return state;
  }
}
