import { Component, Input, OnInit } from '@angular/core';
import { HolObject } from '../../models/hol-object';
import { RolesService } from '../../services/roles.service';

@Component({
  selector: 'app-acl-indicator',
  templateUrl: './acl-indicator.component.html',
  styleUrls: ['./acl-indicator.component.scss'],
})
export class AclIndicatorComponent implements OnInit {
  @Input() object: { companies: string[] };
  companiesColors: any;
  shouldDisplay = false;

  constructor(private rolesService: RolesService) {}

  async ngOnInit() {
    this.companiesColors = await this.rolesService.getCompaniesColors();
    this.shouldDisplay = Object.keys(this.companiesColors).length > 1;
  }
}
