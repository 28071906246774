import { cloneDeep } from 'lodash';
import { OclLinkedDisplayedItemsActionTypes, LinkedDisplayedItemsActions } from './ocl-linked-displayed-items.actions';
import { OclLinkedDisplayedItem } from '../../models/ocl-linked-displayed-items.model';

const oclLinkedDisplayedItemInitialState: OclLinkedDisplayedItem[] = [];

// tslint:disable-next-line: max-line-length
export function oclLinkedDisplayedItemsReducer(
  state: any = cloneDeep(oclLinkedDisplayedItemInitialState),
  action: LinkedDisplayedItemsActions
): OclLinkedDisplayedItem[] {
  switch (action.type) {
    case OclLinkedDisplayedItemsActionTypes.ADD_OR_UPDATE_LINKED_DISPLAYED_ITEMS:
      const bufferLinkedDisplayedItemsToUpdate = cloneDeep(state);
      action.itemsToUpdate.forEach(itemToUpdate => {
        // tslint:disable-next-line: max-line-length
        const bufferStateItemToUpdateIndex = bufferLinkedDisplayedItemsToUpdate.findIndex(
          bufferItem => bufferItem.objectId === itemToUpdate.objectId
        );
        if (bufferStateItemToUpdateIndex !== -1) {
          if (itemToUpdate.isParent) {
            bufferLinkedDisplayedItemsToUpdate[bufferStateItemToUpdateIndex].isParent = itemToUpdate.isParent;
            bufferLinkedDisplayedItemsToUpdate[bufferStateItemToUpdateIndex].color = action.color;
            bufferLinkedDisplayedItemsToUpdate[bufferStateItemToUpdateIndex].linkedUid = action.linkedUid;
          } else {
            bufferLinkedDisplayedItemsToUpdate[bufferStateItemToUpdateIndex].linkedUids.unshift(action.linkedUid);
            bufferLinkedDisplayedItemsToUpdate[bufferStateItemToUpdateIndex].linkedColors.unshift(action.color);
          }
        } else {
          bufferLinkedDisplayedItemsToUpdate.push({
            linkedUids: itemToUpdate.isParent ? [] : [action.linkedUid],
            linkedColors: itemToUpdate.isParent ? [] : [action.color],
            isParent: itemToUpdate.isParent,
            color: itemToUpdate.isParent ? action.color : undefined,
            objectId: itemToUpdate.objectId,
            linkedUid: itemToUpdate.isParent ? action.linkedUid : undefined,
          });
        }
      });
      return cloneDeep(bufferLinkedDisplayedItemsToUpdate);
    case OclLinkedDisplayedItemsActionTypes.REMOVE_LINKED_DISPLAYED_ITEMS:
      let bufferLinkedDisplayedItemsToRemove = cloneDeep(state);
      bufferLinkedDisplayedItemsToRemove = bufferLinkedDisplayedItemsToRemove.map(itemToRemove => {
        if (itemToRemove.linkedUid === action.linkedUid) {
          itemToRemove.linkedUid = undefined;
          itemToRemove.color = undefined;
          itemToRemove.isParent = false;
        }
        if (itemToRemove.linkedUids.includes(action.linkedUid)) {
          itemToRemove.linkedUids.splice(itemToRemove.linkedUids.indexOf(action.linkedUid), 1);
          itemToRemove.linkedColors.splice(itemToRemove.linkedColors.indexOf(action.color), 1);
        }
        return itemToRemove;
      });
      return cloneDeep(bufferLinkedDisplayedItemsToRemove.filter(el => el.linkedUids.length !== 0 || el.isParent));
    case OclLinkedDisplayedItemsActionTypes.RESET_LINKED_DISPLAYED_ITEMS:
      return [];
    default:
      return state;
  }
}
