import { EventEmitter, Injectable, NgZone } from '@angular/core';
import * as Raven from 'raven-js';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  public forceLogout: EventEmitter<Parse.Error> = new EventEmitter<Parse.Error>();
  private queriesCount = 0;

  constructor(private dialog: MatDialog, private zone: NgZone) {}

  public getQueriesCount(): number {
    return this.queriesCount;
  }

  public async performCountQuery(
    query: Parse.Query,
    successCallback?: (count: number) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<number> {
    this.queriesCount++;

    try {
      const count = await query.count();
      return this.manageSuccess(count, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performDestroyQuery<T extends Parse.Object>(
    object: T,
    successCallback?: (result: T) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T> {
    this.queriesCount++;

    try {
      const result = await object.destroy();
      return this.manageSuccess(result, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performDestroyAllQuery<T extends Parse.Object>(
    list: Parse.Object[],
    successCallback?: (result: T[]) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T[]> {
    this.queriesCount++;

    try {
      const results = await Parse.Object.destroyAll(list);
      return this.manageSuccess(results, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performGetQuery<T extends Parse.Object>(
    query: Parse.Query,
    objectId: string,
    successCallback?: (result: T) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T> {
    this.queriesCount++;

    try {
      const result = await query.get(objectId);
      return this.manageSuccess(result, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performFirstQuery<T extends Parse.Object>(
    query: Parse.Query,
    successCallback?: (result: T) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T> {
    this.queriesCount++;

    try {
      const result = await query.first();
      return this.manageSuccess(result, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performFindQuery<T extends Parse.Object>(
    query: Parse.Query,
    successCallback?: (result: T[]) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T[]> {
    this.queriesCount++;

    try {
      const results = await query.find();
      return this.manageSuccess(results, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performFindAllQuery<T extends Parse.Object>(
    query: Parse.Query,
    successCallback?: (result: T[]) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T[]> {
    this.queriesCount++;

    try {
      query.limit(1000);
      const results = await query.find();
      return this.manageSuccess(results, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performSaveQuery<T extends Parse.Object>(
    object: T,
    attrs?: any,
    successCallback?: (result: T) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T> {
    this.queriesCount++;

    try {
      const result = await object.save(attrs);
      return this.manageSuccess(result, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performSaveAllQuery<T extends Parse.Object>(
    list: T[],
    successCallback?: (result: T[]) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T[]> {
    this.queriesCount++;

    try {
      const results = await Parse.Object.saveAll(list);
      return this.manageSuccess(results, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  public async performCloudCode<T>(
    functionName: string,
    data: any,
    successCallback?: (result: T) => void,
    errorCallback?: (error: Parse.Error) => void
  ): Promise<T> {
    this.queriesCount++;

    try {
      const results = await Parse.Cloud.run(functionName, data);
      return this.manageSuccess(results, successCallback);
    } catch (error) {
      throw this.manageErrors(error, errorCallback);
    }
  }

  private manageErrors(error: Parse.Error, errorCallback): Parse.Error {
    this.queriesCount--;
    if (errorCallback) {
      this.zone.run(() => {
        errorCallback(error);
      });
    }
    console.warn('ParseError', JSON.stringify(error));
    if (error && error.code === 209) {
      this.forceLogout.emit(error);
    }
    return error;
  }

  private manageSuccess(results: any, successCallback?: (result: any) => void): any {
    this.queriesCount--;
    try {
      if (successCallback) {
        this.zone.run(() => {
          successCallback(results);
        });
      }
      return results;
    } catch (ex) {
      Raven.captureException(ex);
      throw ex;
    }
  }
}
