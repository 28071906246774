import { OptionsService } from 'src/app/common/services/options.service';
import { HolRole } from '../models/hol-role';
import { HolNextInfo } from '../models/hol-next-info.model';
import * as moment from 'moment';
import { uniqBy, identity } from 'lodash';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(@Inject('CONSTANTS') public CONSTANTS) {}

  private static d2h(d) {
    return d.toString(16);
  } // convert a decimal value to hex

  private static h2d(h) {
    return parseInt(h, 16);
  } // convert a hex value to decimal

  public static mixColors(color1: string = 'ffffff', color2: string = '000000', weight = 50) {
    color1 = color1.replace('#', '');
    color2 = color2.replace('#', '');
    if (color1.length === 3) {
      color1 = `${color1[0]}${color1[0]}${color1[1]}${color1[1]}${color1[2]}${color1[2]}`;
    }
    if (color2.length === 3) {
      color2 = `${color2[0]}${color2[0]}${color2[1]}${color2[1]}${color2[2]}${color2[2]}`;
    }

    let color = '#';

    for (let i = 0; i <= 5; i += 2) {
      // loop through each of the 3 hex pairs-red, green, and blue
      const v1 = HelperService.h2d(color1.substr(i, 2)); // extract the current pairs
      const v2 = HelperService.h2d(color2.substr(i, 2));

      // combine the current pairs from each source color, according to the specified weight
      let val = HelperService.d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

      while (val.length < 2) {
        val = '0' + val;
      } // prepend a '0' if val results in a single digit

      color += val; // concatenate val to our new color string
    }

    return color; // PROFIT!
  }

  generateWarningNextInfoClass(info: HolNextInfo): string {
    if (info.done && info.manual) {
      return 'hol-done';
    }
    if (info.done && !info.manual) {
      return 'hol-affected';
    }
    if (moment(info.nextInfoTime).isBefore(moment())) {
      return 'elapsed';
    }
    if (moment(info.nextInfoTime).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING) {
      return 'almost-elapsed';
    }
  }

  parseACL(acl: Parse.ACL) {
    const companies = [];
    if (!acl) {
      return;
    }
    Object.entries(acl.permissionsById).forEach(([key, value]) => {
      const role: any = value;
      if ((key.startsWith('role:') && role.read) || role.write) {
        const roleName = key.replace('role:', '');
        if (HolRole.ROLE_PARTS_REGEXP.test(roleName)) {
          const parts = HolRole.ROLE_PARTS_REGEXP.exec(roleName);
          companies.push(parts[2]);
        }
      }
    });
    return uniqBy(companies, identity).sort((a, b) => (a < b ? -1 : 1));
  }

  removeDiacritics(str): string {
    // Remove accents, apostrophe and replace all spaces by underscore
    return str
      .normalize('NFD')
      .replaceAll(/[\u0300-\u036f]/g, '')
      .replaceAll(/[^\u0000-\u007E]/g, '')
      .replaceAll(/ /gi, '_');
  }
}
